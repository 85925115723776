import PolicyHolderView from "@/views/policyholder/PolicyHolderView.vue";
import DeepLinkView from "@/views/policyholder/PolicyHolderDeeplinkView.vue";
import FinalizeCancel from "@/views/policyholder/PolicyHolderFinalizeCancel.vue";
import PolicyDetail from "@/components/routes/PolicyDetail.vue";
import PolicyHolderCreateView from "@/views/policyholder/PolicyHolderCreateView.vue";
import PolicyTest from "@/views/policyholder/SignAndPayView.vue";
import SelectScreen from "@/components/policy-select/SelectScreen.vue";

import store from "@/store";
import Vue from "vue";
import { Constants } from "../../config";

// shows bootstrap looking error modal when insured user's link has expired
// must be imported, isn't used everywhere consistently atm
// this one might not even be used, the one in actions.js is used currently
export const errorHandler = (error) => {
  Vue.prototype.$msgbox({
    title: "ERROR",
    message: error.response?.data?.payload?.message
  });
};

/** @type {import("vue-router").RouteConfig[]} */
const routes = [
  {
    path: "/policyholder",
    component: PolicyHolderView,
    redirect: {
      name: "generatePolicyEmail"
    },
    name: "policyHolder",

    beforeEnter(_to, _from, next) {
      next();
      // debugger
    },

    meta: {
      title: "Validate",
      theme: "commercial",
      isHiddenFromSideNav: true
    },

    children: [
      /**
       * Deeplink New
       */
      {
        path: "new/:encryptedPayload?",
        props: true,
        component: DeepLinkView,

        name: "newpolicyHolderDeep",

        meta: {
          title: "Customer Portal",
          auth: false,
          theme() {
            const productNo = this.$store.getters["policy/productNo"];

            const isEarthquake =
              this.$store?.getters["policy/productNo"] === 4 ||
              this.$store?.getters["policy/productNo"] === 5 ||
              this.$store?.getters["policy/policyData"]?.productId === 4 ||
              this.$store?.getters["policy/policyData"]?.productId === 5;

            if (Constants.JUMPSTART_ENABLED && isEarthquake) {
              return "earthquake";
            }
            return productNo === 1 ? "neptune" : "commercial";
          }
        },

        async beforeEnter(to, from, next) {
          const deepLink = to.params?.encryptedPayload;

          // First, check if payload is trusted

          try {
            /** @type {import('axios').AxiosResponse} */
            const {
              data: { statusCode, payload }
            } = await store.dispatch("auth/validateWithDeepLink", {
              deepLink
            });

            if (statusCode === 200) {
              let policyNo;
              // attempt tp get policyNo from payload
              if (payload.policyNo) {
                policyNo = payload.policyNo;
                // grab policyNo from payload.policy
              } else if (payload.policy && payload.policy.policyNo) {
                policyNo = payload.policy.policyNo;
                // Check to see if user only has one policy and grab its policy
              } else if (Array.isArray(payload.policies) && payload.policies.length > 0) {
                const [policy] = payload.policies;
                policyNo = policy.policyNo;
                // otherwise navigate to the select screen
              }

              // Loads all data to see if all has been paid or not
              await store.dispatch("policy/getPolicyDetails", policyNo);

              /** @type {neptune.IPolicyData} */
              const { isSigned, isPaid } = store.getters["policy/allData"];
              const userSigned = isSigned.some((d) => d.isSigned && d.party === 2);

              // If signed and paid for, go right to the detail page

              if (userSigned && isPaid) {
                next("/policyholder/select");
              } else {
                next("/policyholder/welcome");
              }
            } else {
              next();
            }

            next();
          } catch (error) {
            if (error.response && error.response.data) {
              const { payload } = error.response.data;
              const ProductId = payload?.ProductId ?? 2;
              const url =
                ProductId === 1 ? `/policyholder/generate?residential=1` : `/policyholder/generate`;
              return next(url);
            }
            next("/policyholder/generate");
          }
        }
      },

      /**
       * Deeplink Cancel
       */
      {
        path: "cancel/:encryptedPayload?",
        props: true,
        component: DeepLinkView,

        name: "cancelpolicyHolderDeep",

        meta: {
          title: "Neptune Flood - Policy Cancellation",
          auth: false
        },

        async beforeEnter(to, from, next) {
          const deepLink = to.params?.encryptedPayload;
          // First, check if payload is trusted

          /** @type {import('axios').AxiosResponse} */
          const {
            data: { statusCode, payload }
          } = await store.dispatch("auth/validateWithDeepLink", {
            deepLink
          });

          if (statusCode === 200) {
            const {
              policy: {
                policy: { policyNo }
              }
            } = payload;

            // Loads all data to see if all has been paid or not
            await store.dispatch(
              "policy/getPolicyDetails",
              policyNo || store.getters["policy/AllData"].policyNo
            );

            /** @type {neptune.IPolicyData} */
            const { isSigned, isPaid, policyNo: updatedPolicyNo } = store.getters["policy/allData"];

            const userSigned = isSigned.some((d) => d.isSigned && d.party === 2);

            // If signed and paid for, go right to the detail page
            if (userSigned && isPaid) {
              next("/policyholder/policies/" + updatedPolicyNo);
            } else {
              // Otherwise, goto welcome
              next("/policyholder/cancel-confirm");
            }
          } else {
            next();
          }

          next();
        }
      },

      /**
       * Cancellation Actions
       */
      {
        path: "cancel-confirm",
        component: FinalizeCancel,
        name: "cancelConfirm",
        meta: {
          title: "Complete your Cancellation",
          auth: true
        }
      },

      /**
       * Authenticate
       */
      {
        path: "authenticate/:encryptedPayload?",
        props: true,
        component: DeepLinkView,

        name: "newpolicyHolderAuthenticate",

        meta: {
          title: "Customer Portal",
          auth: false
        },

        async beforeEnter(to, from, next) {
          const deepLink = to.params?.encryptedPayload;
          const loginRoute = "/policyholder/generate";

          // First, check if payload is trusted
          if (!deepLink) {
            next(loginRoute);
          }

          /** @type {import('axios').AxiosResponse} */
          const {
            data: { statusCode, payload }
          } = await store.dispatch("auth/validateWithDeepLink", {
            deepLink
          });

          if (statusCode !== 200) {
            next(loginRoute);
          }
          let policyNumber = "";
          if (payload.policy !== null) {
            const {
              policy: { policyNo }
            } = payload;
            policyNumber = policyNo;
          } else {
            policyNumber = payload.policies[0].policyNo;
          }

          // Loads all data to see if all has been paid or not
          await store.dispatch(
            "policy/getPolicyDetails",
            policyNumber || store.getters["policy/AllData"].policyNo
          );

          /** @type {neptune.IPolicyData} */
          const { isSigned, isPaid } = store.getters["policy/allData"];

          const userSigned = isSigned.some((d) => d.isSigned && d.party === 2);

          // If signed and paid for, go right to the detail page
          if (userSigned && isPaid) {
            next("/policyholder/select");
          } else {
            // Otherwise, goto welcome
            // next('/policyholder/cancel-confirm')
            next("/policyholder/welcome");
          }
        }
      },

      /**
       * New Email
       */
      {
        path: "generate",
        component: PolicyHolderCreateView,
        name: "generatePolicyEmail",

        meta: {
          title: "Customer Portal",
          auth: false
        }
      },

      /**
       * Welcome Screen
       */
      {
        path: "welcome",
        name: "policyWelcome",
        component: PolicyTest,
        props: {
          step: 0
        },
        meta: {
          title: "Welcome",
          sidebar: true,
          theme() {
            const productNo = this.$store.getters["policy/productNo"];

            const isEarthquake =
              this.$store?.getters["policy/productNo"] === 4 ||
              this.$store?.getters["policy/productNo"] === 5 ||
              this.$store?.getters["policy/policyData"]?.productId === 4 ||
              this.$store?.getters["policy/policyData"]?.productId === 5;

            if (Constants.JUMPSTART_ENABLED && isEarthquake) {
              return "earthquake";
            }
            return productNo === 1 ? "neptune" : "commercial";
          }
        }
      },

      /**
       * Sign Documents
       */
      {
        path: "documents",
        name: "policyDocuments",
        component: PolicyTest,
        props: {
          step: 1
        },
        meta: {
          title: "Policy Documents",
          sidebar: true
        }
      },

      /**
       * Make payments
       */
      {
        path: "payments",
        name: "policyPayments",
        component: PolicyTest,
        props: {
          step: 2
        },
        meta: {
          title: "Payments",
          sidebar: true
        }
      },

      /**
       * Make payments
       */
      {
        path: "payments/complete",
        name: "policyPaymentsComplete",
        component: PolicyTest,
        props: {
          step: 3
        },
        meta: {
          title: "Payments Complete",
          sidebar: true
        }
      },

      /**
       * Testing new policy sign and pay
       */
      {
        path: "test",
        component: PolicyTest,
        props: (r) => ({
          policyNo: r.query.policyNo
        })
      }
    ]
  },
  // select screen
  {
    meta: {
      title: "Policy Select",
      description: "Select a policy to view.",
      isHiddenFromSideNav: true
    },
    name: "selectScreen",
    path: "/policyholder/select",
    component: SelectScreen,
    beforeEnter(to, from, next) {
      const policies = store.state.policy.policyList;
      const deepLink = store.state.auth.deepLink;

      if (Array.isArray(policies) && policies.length) {
        next();
      } else if (deepLink) {
        next({
          path: `policyholder/authenticate`,
          name: "newpolicyHolderAuthenticate",
          params: {
            encryptedPayload: deepLink
          }
        });
      } else {
        next("/policyholder/generate");
      }
    }
  },

  /**
   * Full policy view
   */
  {
    meta: {
      title: "Customer Policy Details",
      description: "Manage all of your policies in one place.",
      icon: "fa-home",
      userType: 2,
      isHiddenFromSideNav: true,
      theme() {
        const productNo = this.$store.getters["policy/productNo"];

        const isEarthquake =
          this.$store?.getters["policy/productNo"] === 4 ||
          this.$store?.getters["policy/productNo"] === 5 ||
          this.$store?.getters["policy/policyData"]?.productId === 4 ||
          this.$store?.getters["policy/policyData"]?.productId === 5;
        if (Constants.JUMPSTART_ENABLED && isEarthquake) {
          return "earthquake";
        }
        return productNo === 1 ? "neptune" : "commercial";
      }
    },
    name: "policyHolderDetail",
    path: "/policyholder/policies/:policyNo?/:action?",
    props: true,
    async beforeEnter(to, _from, next) {
      await store.dispatch("policy/getActiveTransactions", [to.params.policyNo, true]);
      next();
    },
    component: PolicyDetail
  },
  {
    meta: {
      title: "Receipts",
      isHiddenFromSideNav: true
    },
    path: "/policyholder/policies/:policyNo/receipt/:policyPaymentId",
    props: true,
    component: () => import(/* webpackChunkName: "Receipts" */ "@/components/receipts/Receipts.vue")
  }
];

export default routes;
