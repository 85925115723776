<template>
  <div class="invoice-details">
    <div v-if="Invoices.invoices.length > 0" class="border border-gray-200 rounded-md">
      <table class="text-xs lg:text-sm " style="width: 100%; text-align: center;">
        <tr class="bg-gray-50 border-b border-gray-200">
          <th data-test="invoiceIdHeader">ID</th>
          <th data-test="invoiceDateHeader">Date</th>
          <th data-test="invoicePremiumHeader">Premium</th>
          <th data-test="invoiceTotalHeader">Total</th>
          <th data-test="invoiceActionsHeader">Actions</th>
        </tr>
        <tr v-for="(invoice, key) in Invoices.invoices" :key="key" class="text-xs">
          <!-- ID -->
          <td
            class="!text-left border-r !border-b border-gray-200 "
            :data-test="`invoiceId-${key}`"
          >
            {{ invoice.invoiceId }}
          </td>
          <td
            class="!text-left border-r !border-b border-gray-200"
            :data-test="`invoiceCreated-${key}`"
          >
            {{ invoice.createdAt | shortDateSimple }}
          </td>
          <!-- Premium -->
          <td
            class="!text-right border-r !border-b border-gray-200"
            :data-test="`invoicePremium-${key}`"
          >
            {{ invoice.grossPremium | currency }}
          </td>
          <td
            class="!text-right border-r !border-b border-gray-200"
            :data-test="`invoiceTotalPremium-${key}`"
          >
            {{ invoice.totalPremium | currency }}
          </td>
          <td class="invoice-button !text-center !border-b border-gray-200">
            <div class="!py-2">
              <a
                v-if="isInvoicePresent(invoice.invoiceId)"
                class="text-white font-bold text-center px-3.5 py-1.5 rounded-md min-w-[80px] text-xs whitespace-nowrap"
                :class="{
                  'bg-deepsea-full/10 text-deepsea-full hover:text-deepsea-full hover:bg-deepsea-full/20 hover:!no-underline': !$isEarthquake,
                  'bg-brand-orange/10 text-brand-orange hover:text-brand-orange hover:bg-brand-orange/20 hover:!no-underline': $isEarthquake
                }"
                :data-test="`invoiceView-${key}`"
                @click="onSubmit(invoice.invoiceId)"
              >
                View Invoice
              </a>
            </div>
          </td>
        </tr>
        <tr v-if="Invoices.invoices.length > 0" class="text-xs">
          <td class=" "></td>
          <td class="!text-right">
            <strong>Totals</strong>
          </td>
          <td data-test="invoiceGrossTotal" class="!text-right  border-x border-gray-200">
            {{ (InvoiceTotals.grossPremium + pendingGrossPremium) | currency }}
          </td>
          <!--<td style="border:1px solid #c5c5c5; border-collapse:collapse">{{ InvoiceTotals.policyFee | currency}}</td>-->
          <!--<td style="border:1px solid #c5c5c5; border-collapse:collapse">{{ InvoiceTotals.taxes | currency}}</td>-->
          <td data-test="invoiceTotal" class="!text-right  border-x border-gray-200">
            {{ Invoices.total | currency }}
          </td>

          <td colspan="2" class="bg-gray-100 text-red-500 min-w-[175px] !text-center">
            <div>
              <strong v-if="computedTotal < 0" data-test="refundAmount">Refund Amount</strong>
              <strong v-else data-test="amountDue">Amount Due</strong>
            </div>

            <div>
              <strong v-if="pendingTotalPremium" data-test="invoiceAmountDue"
                >{{ computedTotal === 0 ? "0.00" : computedTotal | currency }}
              </strong>

              <strong v-else data-test="invoiceAmountDue">{{
                Invoices.due === 0 ? "$0.00" : Invoices.due | currency
              }}</strong>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { NumberFiltersMixin, DateFiltersMixin } from "../../policy-wizard/utils/mixins/formatting";
import { Constants } from "@/config";

export default {
  mixins: [NumberFiltersMixin, DateFiltersMixin],
  props: {
    policyNo: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      invoiceVersions: []
    };
  },
  computed: {
    computedTotal() {
      return this.Invoices.due - this.pendingCancelTotalPremium;
    },
    pendingTotalPremium() {
      return this.$store.getters["policy/allData"].pendingTotalPremium;
    },
    newDueDate() {
      // If transaction effective date <= policy inception date -> due date = inception date
      // If transaction effective date > inception date -> due date for that invoice = effective date.
      const transEffectiveDate = this.$store?.state?.policy?.transactions[0]?.effectiveDate;
      const policyInceptionDate = this.$store?.getters["policy/allData"]?.inceptionDate;

      if (transEffectiveDate <= policyInceptionDate) {
        return policyInceptionDate;
      } else if (transEffectiveDate > policyInceptionDate) {
        return transEffectiveDate;
      }
    },
    Invoices() {
      const {
        policy: { confirmationData }
      } = this.$store.state;
      return confirmationData?.invoices;
    },
    isPolicyCancelled() {
      const {
        policy: { confirmationData }
      } = this.$store.state;
      return confirmationData?.isPolicyCancelled;
    },
    isCancelPending() {
      const {
        policy: { confirmationData }
      } = this.$store.state;
      return confirmationData?.isPendingCancel;
    },
    InvoiceTotals() {
      return this.$store.state.policy?.confirmationData;
    },
    pendingGrossPremium() {
      return Math.abs(this.$store?.state?.policy?.confirmationData?.pendingCancelGrossPremium);
    },
    pendingCancelTotalPremium() {
      return Math.abs(this.$store?.state?.policy?.confirmationData?.pendingCancelTotalPremium);
    },
    /**
     * @type {() => boolean}
     */
    pastDue() {
      return Date.parse(this.newDueDate) <= Date.now();
    },
    daysLeft() {
      return ~~((Date.parse(this.newDueDate) - Date.now()) / 1000 / 60 / 60 / 24);
    },
    signed() {
      const { isSigned } = this.$store.getters["policy/allData"];
      const agent = isSigned.some((i) => i.isSigned && i.party === 1);
      const insured = isSigned.some((i) => i.isSigned && i.party === 2);
      return {
        agent,
        insured
      };
    },
    paid() {
      const { isPaid } = this.$store.getters["policy/allData"];
      return isPaid;
    }
  },
  async mounted() {
    const policyNo = this.policyNo;
    const docs = await this.$store.dispatch("document/getAllPolicyDocuments2", policyNo);
    const invoices = docs.filter((x) => x.docName === "Invoice");
    this.invoiceVersions = invoices[0].versions;
  },
  methods: {
    isInvoicePresent(id) {
      const check = this.invoiceVersions.filter((x) => x.invoiceId === id).map((x) => x.invoiceId);
      return check.includes(id);
    },
    onSubmit(id) {
      const downloadFile = this.invoiceVersions.filter((x) => x.invoiceId === id);
      this.openDocument(downloadFile[0]);
    },
    /** @param {neptune.IPolicyPdfDocument} item */
    openDocument(item) {
      if (!this.canOpen(item)) return;
      const path = Constants.GET_PDF_URL + "?link=" + item.link;
      window.open(path, "_blank");
    },

    /** @param {neptune.IPolicyPdfDocument} item */
    canOpen(item) {
      const { link } = item;

      return !!link;
    },
    showPayments() {
      this.$router.push(this.$route.fullPath + "/pay");
    }
  }
};
</script>
<style lang="scss" scoped>
.invoice {
  &-details {
    position: relative;
  }

  &-item {
    margin-bottom: 3rem;
  }

  &__body {
    display: flex;
  }
  &__field {
    display: flex;
    flex-flow: column;
    // justify-content: space-between;
    flex: 1 1 auto;
    align-items: baseline;
    // border-bottom: 1px dotted #000;
    margin-top: 0.6rem;
    font-size: 0.75rem;
    > * {
      &:last-child {
        font-weight: bold;
      }
    }
  }
  &__footer {
    display: flex;
  }
}

table {
  th,
  td {
    text-align: left;
    padding: 5px 8px;
  }
}
.invoice-button {
  color: #26a97b;
  width: 90px;
  cursor: pointer;
  &:hover {
    color: white;
    filter: brightness(110%);
  }
}
.inside {
  border-bottom: 2px solid #26a97b;
  &:hover {
    cursor: pointer;
    color: #26a97b;
    filter: brightness(100%);
  }
}
</style>
